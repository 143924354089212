import { GET_PLAN_FREQ } from 'src/http/planAndFreq';
import { createApolloClient } from '../../graphql';
import { snackbarInfo } from 'store/utils';

type TFetchPlanAndFreqPosps = {
  countryCode: string;
  type?: string;
};

const apolloClient = createApolloClient();

export const fetchPlanAndFreq = async ({
  countryCode,
  type = 'MEAL',
}: TFetchPlanAndFreqPosps) => {
  try {
    const res = await apolloClient.query({
      query: GET_PLAN_FREQ,
      variables: {
        input: {
          countryCode: countryCode,
          filters: {
            type: type,
          },
        },
        skip: !countryCode,
      },
    });
    return res;
  } catch (error: any) {
    snackbarInfo({
      message: error?.message,
      type: 'error',
    });
    return { error };
  }
};
