import { usePersistentCartItems } from 'store/cartItems';
import {
  countryCurrency,
  countryStartingPriceMapping,
} from './constant/countryList';
import { getDecimalPricePerMeal } from './helpers';
import useAuth from './useAuth';
import {
  allvarinatMappingVar,
  productFlatListVar,
  sidesPlanDetailVar,
} from 'store/productsFlatList';
import { useReactiveVar } from '@apollo/client';
import { countryCodeForEventVar } from 'store/utils';
import { countryDetailVar } from 'store/country';
import { usePersistentplanAndFreq } from 'store/planAndFreq';
import { fetchPlanAndFreqVar } from 'store/planAndFreqnew';
import { useEffect } from 'react';
import { fetchCategorizedProduct } from './fetchApi/fetchCategorizedProduct';
import {
  getCategorizedProductsType,
  plansType,
  productType,
  TfrequenciesV2,
} from './types';
import { fetchPlanAndFreq } from './fetchApi/fetchPlanAndFreq';
import { fetchServiceableCountries } from './fetchApi/fetchServiceableCountries';

export const getUserproperties = ({ firstAddress, user, token }: any) => {
  return {
    customer_address_1: firstAddress?.address_line_1,
    customer_address_2: firstAddress?.address_line_2,
    customer_city: firstAddress?.city,
    customer_country:
      countryStartingPriceMapping[firstAddress?.country_code]?.countryName,
    customer_email: user?.email,
    customer_first_name: user?.firstname,
    customer_last_name: user?.lastname,
    customer_id: user?.id ? user.id.toString() : '',
    customer_order_count: user?.totalOrders.toString(),
    customer_phone: firstAddress?.phone_number,
    customer_province: firstAddress?.stateName,
    customer_province_code: firstAddress?.state,
    customer_total_spent: user?.totalSpent
      ? getDecimalPricePerMeal(user?.totalSpent).toFixed(2)
      : 0,
    customer_zip: firstAddress?.zip_code,

    visitor_type: token ? 'logged_in' : 'guest',
  };
};

export const getProduct = ({
  product,
  selectedPlan,
  variantDetail,
  sideDetail,
  productFlatList,
}: any) => {
  return {
    id: product?.sku,
    name: product.title,
    brand: 'The Cumin Club',
    price: product?.unitsPerSide
      ? String((sideDetail?.price ?? 0) / 100)
      : String((selectedPlan?.price ?? 0) / 100),
    category: productFlatList[product.id]?.category_name,
    variant: variantDetail[product.id]?.variant_name || 'Meal Kits',
    product_id: product.shopifyProductId,
    variant_id: variantDetail[product.id]?.variant_id || '1',
    quantity: '1',
    list: window.location.pathname,
    image: product.image_url,
    url:
      window.location.origin +
      window.location.pathname +
      '?product_id=' +
      product.id,
  };
};

export const getAllProduct = ({
  cartItems,
  productFlatList,
  variantDetail,
  selectedPlan,
  sideDetail,
}: any) => {
  const products =
    Object.keys(cartItems)
      ?.map((items: string, index: number) => {
        if (productFlatList[+items]?.isActive) {
          const product = productFlatList[+items];
          return {
            id: product?.sku,
            name: product.title,
            brand: 'The Cumin Club',
            price: product?.unitsPerSide
              ? String((sideDetail?.price ?? 0) / 100)
              : String((selectedPlan?.price ?? 0) / 100),
            category: product?.category_name,
            variant: variantDetail[product.id]?.variant_name || 'Meal Kits',
            product_id: product.shopifyProductId || '1',
            variant_id: variantDetail[product.id]?.variant_id || '1',
            quantity: cartItems[items] ? cartItems[items].toString() : '0',
            list: window.location.pathname,
            image: product.image_url,
            url:
              window.location.origin +
              window.location.pathname +
              '?product_id=' +
              product.id,
          };
        }
      })
      .filter(Boolean) || [];

  return products;
};

export const elevarAddToCart = ({
  product,
  countryCodeForEvent,
  selectedPlan,
  allvarinatMapping,
  countryId,
  authState,
  sideDetail,
  productFlatList,
}: any) => {
  const key = `${countryId}-${selectedPlan.id}`;
  const variantDetail = allvarinatMapping[key];
  const { user, token } = authState || {};
  const firstAddress = user?.address_list[0] || {};

  const user_properties: any = getUserproperties({ firstAddress, user, token });

  Object.entries(user_properties).forEach(([key, val]) => {
    if (!val) {
      delete user_properties[key];
    }
  });

  const productDetail = getProduct({
    product,
    selectedPlan,
    variantDetail,
    sideDetail,
    productFlatList,
  });

  // @ts-ignore
  window?.ElevarDataLayer?.push({
    event: 'dl_add_to_cart',
    user_properties,
    ecommerce: {
      currencyCode: countryCurrency[countryCodeForEvent],
      add: {
        actionField: {
          list: window.location.pathname, // this should be the collection page URL that user clicked product from
        },
        products: [productDetail], // See the products array below
      },
    },
  });
};

export const elevarRemoveFromCart = ({
  product,
  countryCodeForEvent,
  selectedPlan,
  allvarinatMapping,
  countryId,
  authState,
  sideDetail,
  productFlatList,
}: any) => {
  const key = `${countryId}-${selectedPlan.id}`;
  const variantDetail = allvarinatMapping[key];
  const { user, token } = authState || {};
  const firstAddress = user?.address_list[0] || {};

  const user_properties: any = getUserproperties({ firstAddress, user, token });

  Object.entries(user_properties).forEach(([key, val]) => {
    if (!val) {
      delete user_properties[key];
    }
  });

  const productDetail = getProduct({
    product,
    selectedPlan,
    variantDetail,
    sideDetail,
    productFlatList,
  });

  // @ts-ignore
  window?.ElevarDataLayer?.push({
    event: 'dl_remove_from_cart',
    user_properties,
    ecommerce: {
      currencyCode: countryCurrency[countryCodeForEvent],
      remove: {
        actionField: {
          list: window.location.pathname, // this should be the collection page URL that user clicked product from
        },
        products: [productDetail], // See the products array below
      },
    },
  });
};

export const elevarViewCart = ({
  cartItems,
  productFlatList,
  countryCodeForEvent,
  selectedPlan,
  sideDetail,
  allvarinatMapping,
  countryId,
  authState,
}: any) => {
  const key = `${countryId}-${selectedPlan?.id}`;
  const variantDetail = allvarinatMapping[key];
  const { user, token } = authState || {};
  const firstAddress = user?.address_list[0] || {};

  const user_properties: any = getUserproperties({ firstAddress, user, token });

  Object.entries(user_properties).forEach(([key, val]) => {
    if (!val) {
      delete user_properties[key];
    }
  });

  const impressions = Object.keys(cartItems)
    ?.map((items: string) => {
      if (productFlatList[+items]?.isActive) {
        const product = productFlatList[+items];
        return {
          id: product?.sku,
          position: 1,
          name: product.title,
          brand: 'The Cumin Club',
          price: product?.unitsPerSide
            ? String((sideDetail?.price ?? 0) / 100)
            : String((selectedPlan?.price ?? 0) / 100),
          category: product?.category_name,
          variant: variantDetail[product.id]?.variant_name || 'Meal Kits',
          product_id: product.shopifyProductId || '1',
          variant_id: variantDetail[product.id]?.variant_id || '1',
          quantity: cartItems[items] ? cartItems[items].toString() : '0',
          image: product.image_url,
          url:
            window.location.origin +
            window.location.pathname +
            '?product_id=' +
            product.id,
        };
      }
    })
    .filter(Boolean);

  const totalProductQuantity = impressions?.reduce(
    (acc, product) => {
      if (productFlatList[product?.id]?.unitsPerSide) {
        acc.sideCount += +product?.quantity;
      } else {
        acc.mealCount += +product?.quantity;
      }
      return acc;
    },
    { sideCount: 0, mealCount: 0 }
  );

  const cart_total = impressions?.length
    ? String(
        (
          totalProductQuantity.mealCount * ((selectedPlan?.price ?? 0) / 100) +
          totalProductQuantity.sideCount * ((sideDetail?.price ?? 0) / 100)
        ).toFixed(2)
      )
    : '0.0';

  //@ts-ignore
  window?.ElevarDataLayer &&
    //@ts-ignore
    window?.ElevarDataLayer.push({
      event: 'dl_view_cart',
      cart_total: cart_total,
      user_properties,
      ecommerce: {
        currencyCode: countryCurrency[countryCodeForEvent],
        actionField: {
          list: 'Shopping Cart',
        },
        impressions,
      },
    });
};

export const elevarUserData = ({
  authState,
  cartItems,
  productFlatList,
  selectedPlan,
  allvarinatMapping,
  countryId,
  countryCodeForEvent,
  sideDetail,
}: any) => {
  const key = `${countryId}-${selectedPlan?.id}`;
  const variantDetail = allvarinatMapping[key] || {};
  const { user, token } = authState || {};
  const firstAddress = user?.address_list[0] || {};

  const products = getAllProduct({
    cartItems,
    productFlatList,
    variantDetail,
    selectedPlan,
    sideDetail,
  });

  const userproperties: any = getUserproperties({ firstAddress, user, token });

  const ecommerce: any = {
    currencyCode: countryCurrency[countryCodeForEvent] || 'USD',
    cart_contents: {
      products: products,
    },
  };

  Object.entries(products).forEach(([key, val]) => {
    if (!val) {
      delete userproperties[key];
    }
  });

  Object.entries(userproperties).forEach(([key, val]) => {
    if (!val) {
      delete userproperties[key];
    }
  });

  const totalProductQuantity = products?.reduce(
    (acc, product) => {
      if (productFlatList[product?.id]?.unitsPerSide) {
        acc.sideCount += +product?.quantity;
      } else {
        acc.mealCount += +product?.quantity;
      }
      return acc;
    },
    { sideCount: 0, mealCount: 0 }
  );

  const cart_total = products?.length
    ? String(
        (
          totalProductQuantity.mealCount * ((selectedPlan?.price ?? 0) / 100) +
          totalProductQuantity.sideCount * ((sideDetail?.price ?? 0) / 100)
        ).toFixed(2)
      )
    : '0.0';

  const user_detail: any = {
    user_properties: userproperties,
    ecommerce,
  };

  //@ts-ignore
  window?.ElevarDataLayer &&
    //@ts-ignore
    window?.ElevarDataLayer?.push({
      event: 'dl_user_data',
      cart_total: cart_total,
      ...user_detail,
    });
};

export const elevartViewItem = ({
  product,
  authState,
  selectedPlan,
  allvarinatMapping,
  selectedCountry,
  countryCodeForEvent,
  sideDetail,
  productFlatList,
}: any) => {
  const key = `${selectedCountry?.id}-${selectedPlan?.id}`;
  const variantDetail = allvarinatMapping[key];
  const { user, token } = authState || {};
  const firstAddress = user?.address_list[0];

  const user_properties: any = getUserproperties({
    firstAddress,
    user,
    token,
  });

  Object.entries(user_properties).forEach(([key, val]) => {
    if (!val) {
      delete user_properties[key];
    }
  });

  const productDetail = getProduct({
    product,
    selectedPlan,
    variantDetail,
    sideDetail,
    productFlatList,
  });

  // @ts-ignore
  window?.ElevarDataLayer?.push({
    event: 'dl_view_item',
    user_properties,
    ecommerce: {
      currencyCode: countryCurrency[countryCodeForEvent],
      detail: {
        actionField: {
          list: window.location.pathname, // this should be the collection page URL that user clicked product from
          action: 'detail',
        },
        products: [
          {
            ...productDetail,
            compare_at_price: product?.unitsPerSide
              ? String((sideDetail?.price ?? 0) / 100)
              : String((selectedPlan?.price ?? 0) / 100),
          },
        ], // See the products array below
      },
    },
  });
};

export const useElevar = () => {
  const { authState } = useAuth();
  const cartItems = usePersistentCartItems();
  const { productFlatList } = useReactiveVar(productFlatListVar);
  const allvarinatMapping = useReactiveVar(allvarinatMappingVar);
  const countryCodeForEvent = useReactiveVar(countryCodeForEventVar);
  const sideDetail = useReactiveVar(sidesPlanDetailVar);
  const serviceableCountries = useReactiveVar(countryDetailVar);
  const planAndFreq = usePersistentplanAndFreq();
  const planAndFreqVar = useReactiveVar(fetchPlanAndFreqVar);

  const selectedCountry =
    serviceableCountries?.find(
      (country: any) => country.country_code === countryCodeForEvent
    ) || {};

  const selectedPlanId = planAndFreq?.selectedPlanId;
  const selectedPlan = planAndFreqVar?.plans?.[0]?.find(
    (i) => i.id === selectedPlanId
  );

  // for servicable country
  useEffect(() => {
    if (!serviceableCountries?.length) {
      const fetchData = async () => {
        try {
          const response: any = await fetchServiceableCountries();
          if (response?.data?.getServiceableCountries) {
            countryDetailVar(response?.data?.getServiceableCountries);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      // Call the async function
      fetchData();
    }
  }, [serviceableCountries?.length]);

  // for product
  useEffect(() => {
    if (!Object.keys(productFlatList).length && countryCodeForEvent) {
      const fetchData = async () => {
        try {
          const response = await fetchCategorizedProduct({
            countryCode: countryCodeForEvent,
          });
          if (response?.data?.getCategorizedProducts) {
            const {
              categories,
              allVariantMapping,
            }: {
              categories: getCategorizedProductsType[];
              allVariantMapping: any;
            } = response.data.getCategorizedProducts || {};
            const productFlatList: { [product_id: number]: productType } = {};
            categories?.map((category) => {
              if (category.uiType !== 'BUNDLE') {
                category.products.map((product) => {
                  if (!productFlatList[product.id]) {
                    productFlatList[product.id] = {
                      ...product,
                      category_name: category.category_name,
                    };
                  }
                })
              }
            });
            productFlatListVar({
              productFlatList: productFlatList,
            });
            allvarinatMappingVar(allVariantMapping);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      // Call the async function
      fetchData();
    }
  }, [productFlatList, countryCodeForEvent]);

  // for meal plan
  useEffect(() => {
    if (!planAndFreqVar?.plans?.length && countryCodeForEvent) {
      const fetchData = async () => {
        try {
          const response: any = await fetchPlanAndFreq({
            countryCode: countryCodeForEvent,
          });
          if (response?.data?.getPlansAndFrequencies?.plans?.length) {
            const {
              frequencies,
              plans,
              frequenciesV2,
            }: {
              frequencies: string[];
              plans: plansType[];
              frequenciesV2: TfrequenciesV2[];
            } = response?.data?.getPlansAndFrequencies;
            fetchPlanAndFreqVar({
              plans,
              frequencies,
              frequenciesV2,
            });
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      // Call the async function
      fetchData();
    }
  }, [planAndFreqVar, countryCodeForEvent]);

  // for side plan
  useEffect(() => {
    if (!Object.keys(sideDetail)?.length && countryCodeForEvent) {
      const fetchData = async () => {
        try {
          const response: any = await fetchPlanAndFreq({
            countryCode: countryCodeForEvent,
            type: 'SIDE',
          });

          if (response?.data?.getPlansAndFrequencies?.plans) {
            sidesPlanDetailVar(
              response?.data?.getPlansAndFrequencies?.plans[0][0]
            );
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      // Call the async function
      fetchData();
    }
  }, [sideDetail, countryCodeForEvent]);

  const elevarUserDataEvent = () => {
    elevarUserData({
      authState,
      cartItems,
      productFlatList,
      selectedPlan,
      allvarinatMapping,
      countryId: selectedCountry?.id,
      countryCodeForEvent,
      sideDetail,
    });
  };

  const elevarViewCartEvent = () => {
    elevarViewCart({
      cartItems,
      productFlatList,
      countryCodeForEvent,
      selectedPlan,
      sideDetail,
      allvarinatMapping,
      countryId: selectedCountry?.id,
      authState,
    });
  };

  const elevarRemoveFromCartEvent = (product: productType) => {
    elevarRemoveFromCart({
      product,
      countryCodeForEvent,
      selectedPlan,
      allvarinatMapping,
      countryId: selectedCountry?.id,
      authState,
      sideDetail,
      productFlatList,
    });
  };

  const elevarAddToCartEvent = (product: productType) => {
    elevarAddToCart({
      product,
      countryCodeForEvent,
      selectedPlan,
      allvarinatMapping,
      countryId: selectedCountry?.id,
      authState,
      sideDetail,
      productFlatList,
    });
  };

  const elevarViewItemEvent = (product: productType) => {
    elevartViewItem({
      product,
      authState,
      selectedPlan,
      allvarinatMapping,
      selectedCountry,
      countryCodeForEvent,
      sideDetail,
      productFlatList,
    });
  };

  return {
    elevarUserDataEvent,
    elevarViewCartEvent,
    elevarAddToCartEvent,
    elevarRemoveFromCartEvent,
    elevarViewItemEvent,
  };
};
