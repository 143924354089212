import { useState, useEffect } from 'react';
import { ApolloError, useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { createApolloClient } from '../../graphql';
import { allFiltersType, getCategorizedProductsType } from '@utils/types';

export const GET_CATEGORIZED_PRODUCTS = gql`
  query GetCategorizedProducts($input: GetCategorizedProductsInput!) {
    getCategorizedProducts(input: $input) {
      allVariantMapping
      categories {
        category_id
        category_name
        showInUi
        uiType
        categoryImage
        secondaryImages
        categoryDescription
        products {
          id
          title
          taste
          vegan
          image_url
          secondaryImages
          chef_specials
          filter_ids
          spice_level
          product_badge
          requires
          ingredients
          description
          nutrition {
            name
            value
          }
          how_to_prepare
          side {
            id
            unitsPerSide
            title
          }
          isActive
          unitsPerSide
          recommendedUnitsPerSide
          servingsPerContainer
          servingSize
          rating
          sides {
            roundUp
            recommendedUnitsPerSide
            side {
              id
            }
          }
          shopifyProductId
          sku
        }
      }
      all_filters {
        filter_group_id
        filter_group_name
        filters {
          id
          name
          image
          filter_group_id
          label
        }
        filter_group_label
      }
    }
  }
`;

export const getCategorizedProduct = () => {
  const [categories, setData] = useState<getCategorizedProductsType[]>();
  const [filters, setFilterData] = useState<allFiltersType[]>();

  const [productLoading, setProductLoading] = useState<boolean>(false);
  const [productError, setError] = useState<ApolloError | undefined>();

  const apolloClient = createApolloClient();

  const fetchCategorizedProduct = async (country_code: string) => {
    if (country_code) {
      setProductLoading(true);
      try {
        const { data } = await apolloClient.query({
          query: GET_CATEGORIZED_PRODUCTS,
          variables: {
            input: {
              countryCode: country_code,
              filters: {
                types: ['MEAL', 'SIDE'],
              },
            },
          },
        });
        setProductLoading(false);
        const { categories, all_filters } = data?.getCategorizedProducts;

        setData(categories);
        setFilterData(all_filters);
      } catch (error) {
        setProductLoading(false);
        setError(error as ApolloError);
        console.log(error);
      }
    }
  };

  return {
    fetchCategorizedProduct,
    categories,
    filters,
    productLoading,
    productError,
  };
};
