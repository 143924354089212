import { gql } from 'graphql-tag';

export const GET_SERVICEABLE_COUNTRIES_ONLY = gql`
  query GetServiceableCountries {
    getServiceableCountries {
      id
      country_code
      country_name
      country_flag
      iso_alpha_2_code
      currency_symbol
    }
  }
`;
