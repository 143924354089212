import { GET_CATEGORIZED_PRODUCTS } from 'src/http/categorizedProducts';
import { createApolloClient } from '../../graphql';
import { snackbarInfo } from 'store/utils';

type TFetchCategorizedProductPosps = {
  countryCode: string;
  types?: string[];
};

const apolloClient = createApolloClient();

export const fetchCategorizedProduct = async ({
  countryCode,
  types = ['MEAL', 'SIDE'],
}: TFetchCategorizedProductPosps) => {
  try {
    const { data } = await apolloClient.query({
      query: GET_CATEGORIZED_PRODUCTS,
      variables: {
        input: {
          countryCode: countryCode,
          filters: {
            types: types,
          },
        },
      },
    });

    return { data };
  } catch (error) {
    snackbarInfo({
      message: error,
      type: 'error',
    });
    return { error };
  }
};
