import { createApolloClient } from '../../graphql';
import { GET_SERVICEABLE_COUNTRIES_ONLY } from 'src/http/countryAlone';
import { snackbarInfo } from 'store/utils';

const apolloClient = createApolloClient();

export const fetchServiceableCountries = async () => {
  try {
    const res = await apolloClient.query({
      query: GET_SERVICEABLE_COUNTRIES_ONLY,
    });
    return res;
  } catch (error: any) {
    snackbarInfo({
      message: error?.message,
      type: 'error',
    });
    return { error };
  }
};
